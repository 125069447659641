export default function EntryShareForm(el, {
    successClass = 'is-success',
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const submit = form.querySelector('button[type="submit"]');
    const closes = el.querySelectorAll('button[type="button"]');

    submit.removeAttribute('disabled');

    form.onsubmit = e => {
        e.preventDefault();

        submit.setAttribute('disabled', 'true');

        /* eslint-disable no-undef */
        if (typeof dataLayer === 'object') {
            dataLayer.push({
                event: 'share_document',
                eventAction: 'submit',
            });
        }
        /* eslint-enable no-undef */

        const body = new FormData(form);

        fetch('/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body,
        })
            .then(res => res.json().then(json => ({
                status: res.status,
                ...json,
            })))
            .then(({
                status,
                message = '',
                errors = {},
            }) => {
                // Reset all field errros to empty
                Array.from(body.keys()).forEach(name => {
                    events.emit(actions.showFieldError, { name, errors: [] });
                });
                submit.removeAttribute('disabled');

                if (status === 500) {
                    window.alert(message); // eslint-disable-line no-alert

                    return;
                }

                if (status === 400) {
                    Object.entries(errors).forEach(([name, errs]) => {
                        events.emit(actions.showFieldError, { name, errors: errs });
                    });

                    return;
                }

                if (status === 200) {
                    form.remove();
                    el.classList.add(successClass);
                }
            });
    };
    closes.forEach(c => {
        c.onclick = () => {
            events.emit(actions.closeModal);
        };
    });
}
